
import { configureStore } from "@reduxjs/toolkit";
import { loginReducer,appStateReducer } from "./features/appStateSlice";

// Load the persisted login state from local storage
const persistedLoginState = localStorage.getItem("loginState");
const preloadedState = persistedLoginState
  ? { login: JSON.parse(persistedLoginState) }
  : undefined;

export const store = configureStore({
  reducer: {
    login: loginReducer,
    appState: appStateReducer
  },
  preloadedState,
});

export type RootState = ReturnType<typeof store.getState>;