import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

interface SkeletonProps {
  width?: string;
  animation?: 'pulse' | 'wave' | false;
  count?: number;
}

const Skeletoned: React.FC<SkeletonProps> = ({
  width = '100%',
  animation = 'wave',
  count = 1,
}) => {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <Skeleton key={index} width={width} animation={animation} />
  ));

  return <Box sx={{ width: '100%' }}>{skeletons}</Box>;
};

export default Skeletoned;
