import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableView from "../../components/common/TableView";
import HeadLine from "../../components/common/HeadLine";
import { alertError, alertSuccess } from "../../components/common/alertUtils";
import Skeletoned from "../../components/common/Skeletoned";
import { api } from "../../routes/config";

type Props = {};

const FeesInfo = (props: Props) => {
  const { data } = useSelector((state: RootState) => state.login);
  const items = data.item.identificationId;
  const institute_id = data.item.instituteId;

  const [tableData, setTableData] = useState([]);
  const [isLoading,setIsLoading]=useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${api}/public/fees-payment/invoice/list?identificationId=${items}`
        );
        const apiData = await response.json();
        const datas = apiData.item;

        const sortedData = datas.sort(
          (a: any, b: any) =>
            new Date(a.paymentDate).getTime() -
            new Date(b.paymentDate).getTime()
        );
        const reversedData = sortedData.reverse();
        setTableData(reversedData);
        // setTableData(datas);
      } catch (error) {
        alertError("Error fetching data");
      }
      finally {
        setIsLoading(false); 
      }
    };

    fetchData();
  }, [items]);

  const handleIconClick = async (feeInvoiceId: string) => {
    try {
      // First, fetch the invoice data to get the feeInvoiceId
      const response = await fetch(
        `${api}/public/fees-payment/invoice/list?identificationId=${items}`
      );
      const apiData = await response.json();
      const datas = apiData.item;

      const selectedInvoiceData = datas.find(
        (data: any) => data.feeInvoiceId === feeInvoiceId
      );

      // Check if the selectedInvoiceData exists and has feeInvoiceId
      if (selectedInvoiceData && selectedInvoiceData.feeInvoiceId) {
        alertSuccess("Download Successfully");
        const downloadUrl = `${api}/public/money-receipt/download?invoiceIds=${selectedInvoiceData.feeInvoiceId}&instituteId=${institute_id}`;
        window.open(downloadUrl, "_blank");
      }
    } catch (error) {
      alertError("Error fetching data");
    }
  };

  const columns = [
    {
      id: "paymentDate",
      label: "Date",
      showOnResponse: true,
    },
    {
      id: "feeInvoiceId",
      label: "Invoice Id",
      showOnResponse: true,
    },
    {
      id: "feeHeads",
      label: "Fee Head",
      showOnResponse: true,
    },
    {
      id: "feeSubHeads",
      label: "Fee Sub Head",
      showOnResponse: true,
    },
    {
      id: "paymentStatus",
      label: "Payment Status",
      showOnResponse: true,
    },
    {
      id: "paidAmount",
      label: "Paid Amount",
      showOnResponse: true,
    },

    {
      id: "Receipt",
      label: "Receipt",
      showOnResponse: true,
      render: (data: any, Index: number) => (
        <IconButton
          key={data.feeInvoiceId}
          onClick={() => handleIconClick(data?.feeInvoiceId)}
          color="success"
        >
          <FileDownloadIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <HeadLine text={"Fees Information"} />
      {isLoading ? (
          <Skeletoned count={15} animation="wave" />
        ) : tableData && (
        <TableView
        columns={columns}
        dataSource={tableData}
        pagination
      />
      )}
    </>
  );
};

export default FeesInfo;
