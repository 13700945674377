import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoginData } from "../../redux/features/appStateSlice";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import assets from "../../assets";
import CircularProgress from "@mui/material/CircularProgress";
import { alertError, alertSuccess } from "../common/alertUtils";
import { api } from "../../routes/config";

const defaultTheme = createTheme();

const Login = () => {
  const dispatch = useDispatch();
  const [customStudentId, setCustomStudentId] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setOpen(true);
    if (!customStudentId || !instituteId) {
      setOpen(false);
      alertError("Login failed: Invalid Student ID or Institute ID");
      return;
    }
    const response = await fetch(
      `${api}/public/student-portal/?customStudentId=${customStudentId}&instituteId=${instituteId}`
    );
    const data = await response.json();

    if (data.messageType === 1) {
      setOpen(false);
      alertSuccess("Login Success");
      dispatch(
        setLoginData({
          customStudentId: data.customStudentId,
          instituteId: data.instituteId,
          data: data,
        })
      );
      navigate("/");
    } else {
      setOpen(false);
      alertError("Login failed: Invalid ID");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        sx={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={false}
          sm={5}
          md={6}
          sx={{
            backgroundImage: `url(${assets.images.pngImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "fit",
            backgroundPosition: "center",
          }}
        />
        {/* <Grid
          item
          xs={false}
          sm={4}
          md={7}
         ><img src={assets.images.pngImg} alt={assets.images.pngImg} style={{ width:'100%',height:'99vh' }}/></Grid> */}

        <Grid item xs={12} sm={7} md={6} component={Paper} square>
          {/* elevation={6} this is shadow attribute of MUI  */}
          <Box
            sx={{
              my: 15,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ bgcolor: "#0b3f4f" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Avatar
              variant="square"
              style={{
                minWidth: "240px",
                height: "90px",
                marginBottom: "15px",
              }}
              // src={assets.images.logo}
              src={assets.images.softedu}
            />
            <Typography component="h1" variant="h5" color={"#0b3f4f"}>
              Student Portal Login
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Student Id"
                autoFocus
                value={customStudentId}
                onChange={(e) => setCustomStudentId(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Institute Id"
                value={instituteId}
                onChange={(e) => setInstituteId(e.target.value)}
              />

              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: "#0b3f4f" }}
                onClick={handleLogin}
              >
                Login
                {open && (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default Login;
