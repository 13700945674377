import * as React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";


export type AlertSeverity = "success" | "error" | "warning" | "info";

interface ReusableAlertProps {
  open: boolean;
  onClose: () => void;
  severity: AlertSeverity;
  message: string;
}

const ReusableAlert: React.FC<ReusableAlertProps> = ({
  open,
  onClose,
  severity,
  message,
}) => {

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ReusableAlert;