import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import TableView from "../../components/common/TableView";
import HeadLine from "../../components/common/HeadLine";
import { alertError } from "../../components/common/alertUtils";
import Skeletoned from "../../components/common/Skeletoned";
import { api } from "../../routes/config";

type Props = {};

const SubjectInfoPage = (props: Props) => {
  const { data } = useSelector((state: RootState) => state.login);
  const classId = data.item.classId;
  const groupId = data.item.groupId;
  const institute_id = data.item.instituteId;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${api}/public/subject/list/by/class-id/group-id?classId=${classId}&groupId=${groupId}&instituteId=${institute_id}`
        );
        const apiData = await response.json();
        const datas = apiData.item;
        setTableData(datas);
      } catch (error) {
        alertError("Error fetching data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [classId, groupId, institute_id]);
  const columns = [
    {
      id: "subjectName",
      label: "Subject Name",
      showOnResponse: true,
    },
  ];

  return (
    <>
      <HeadLine text={"Subject List"} />
      {isLoading ? (
        <Skeletoned count={15} animation="wave" />
      ) : (
        tableData && (
          <TableView
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        )
      )}
    </>
  );
};

export default SubjectInfoPage;
