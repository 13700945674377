import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  width: "100%",
  color: theme.palette.text.primary,
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#052a3f1c" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));
// const Item2 = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "left",
//   color: theme.palette.mode === "dark" ? "#1A2027" : "red",
// }));

type Props = {};

const HomePage = (props: Props) => {
  const { data } = useSelector((state: RootState) => state.login);
  const items = data.item;

  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 1, marginTop: "20px" }}>
        <StyledPaper
          sx={{
            my: 4,
            mx: "auto",
            p: 2,
          }}
        >
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar
                sx={{ margin: "auto", width: 120, height: 120 }}
                alt={items.studentName}
                src={`data:image/png;base64,${items.imageName}`}
                variant="square"
              />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant="h5">
                <span style={{ color: "#087f9b", fontWeight: "bold" }}>
                  {items.studentName}
                </span>{" "}
                'S Profile
              </Typography>
            </Grid>
          </Grid>
        </StyledPaper>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "1fr",
            sm: "repeat(12, 1fr)",
          }}
          gap={2}
        >
          <Box gridColumn="span 3">
            <Item>
              <span>Name </span>:{" "}
              <span style={{ color: "#672323" }}> {items.studentName}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Roll No </span>:{" "}
              <span style={{ color: "#672323" }}> {items.studentRoll}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Student ID </span>:{" "}
              <span style={{ color: "#672323" }}> {items.customStudentId}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Gender </span>:{" "}
              <span style={{ color: "#672323" }}> {items.studentGender}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Religion </span>:{" "}
              <span style={{ color: "#672323" }}> {items.studentReligion}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Category </span>:{" "}
              <span style={{ color: "#672323" }}>
                {" "}
                {items.studentCategoryName}
              </span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Group Name </span>:{" "}
              <span style={{ color: "#672323" }}> {items.groupName}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Class Name </span>:{" "}
              <span style={{ color: "#672323" }}> {items.className}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Shift Name </span>:{" "}
              <span style={{ color: "#672323" }}> {items.shiftName}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Section Name </span>:{" "}
              <span style={{ color: "#672323" }}> {items.sectionName}</span>
            </Item>
          </Box>
          <Box gridColumn="span 3">
            <Item>
              <span> Class Config Name </span>:{" "}
              <span style={{ color: "#672323" }}> {items.classConfigName}</span>
            </Item>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
