import React from 'react';

type Props = {};

const AttendanceInfoPage = (props: Props) => {
    return (
        <div>This Attendance Page</div>
    );
};

export default AttendanceInfoPage;