import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isSidebarOpen }: { isSidebarOpen: boolean }) => {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/");
  };
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isSidebarOpen ? sizeConfigs.sidebar.width : 0,
        flexShrink: 0,
        transition: "width 0.2s",
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? sizeConfigs.sidebar.width : 0,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          transition: "width 0.2s",
        },
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "40px" }}>
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <Avatar
              style={{
                width: "240px",
                height: "88px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              // src={assets.images.logo}
              src={assets.images.softedu}
              onClick={handleHome}
            />
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;

// import React from 'react';
// import clsx from 'clsx';
// import { makeStyles } from '@mui/styles';
// import { Drawer, List, Avatar, Stack, Toolbar } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import SidebarItem from './SidebarItem';
// import SidebarItemCollapse from './SidebarItemCollapse';
// import assets from '../../assets';
// import colorConfigs from '../../configs/colorConfigs';
// import sizeConfigs from '../../configs/sizeConfigs';
// import appRoutes from '../../routes/appRoutes';

// const drawerWidth = sizeConfigs.sidebar.width;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: 'nowrap',
//   },
//   drawerOpen: {
//     width: drawerWidth,
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   drawerClose: {
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: 'hidden',
//     width: theme.spacing(7) + 1,
//     [theme.breakpoints.up('sm')]: {
//       width: theme.spacing(9) + 1,
//     },
//   },
//   toolbar: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: theme.spacing(0, 1),
//     ...theme.mixins.toolbar,
//   },
//   avatar: {
//     width: '250px',
//     height: '65px',
//     borderRadius: '10px',
//   },
// }));

// const Sidebar = () => {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   const { data } = useSelector((state) => state.login);
//   const items = data.item;

//   return (
//     <div className={classes.root}>
//       <Drawer
//         variant="permanent"
//         className={clsx(classes.drawer, {
//           [classes.drawerOpen]: open,
//           [classes.drawerClose]: !open,
//         })}
//         classes={{
//           paper: clsx({
//             [classes.drawerOpen]: open,
//             [classes.drawerClose]: !open,
//           }),
//         }}
//       >
//         <Toolbar sx={{ marginBottom: '40px' }}>
//           <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
//             <Avatar className={classes.avatar} src={assets.images.logo} />
//           </Stack>
//         </Toolbar>
//         <List disablePadding>
//           {appRoutes.map((route, index) =>
//             route.sidebarProps ? (
//               route.child ? (
//                 <SidebarItemCollapse item={route} key={index} />
//               ) : (
//                 <SidebarItem item={route} key={index} />
//               )
//             ) : null
//           )}
//         </List>
//       </Drawer>
//     </div>
//   );
// };

// export default Sidebar;
