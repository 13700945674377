import * as React from 'react';
import { styled } from '@mui/material/styles';

interface DynamicHeadLineProps {
  text: string;
  textColor?: string;
}

// Define the HeadLine component outside the function
const StyledHeadLine = styled('h2')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
}));

const HeadLine: React.FC<DynamicHeadLineProps> = ({ text, textColor }) => {
  return <StyledHeadLine sx={{ color: textColor || '#d19f80' }}>{text}</StyledHeadLine>;
};

export default HeadLine;