import { useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/features/appStateSlice";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Topbar = ({
  children,
  isSidebarOpen,
  handleSidebarToggle,
  isMobileView,
}: {
  children: React.ReactNode;
  isSidebarOpen: boolean;
  handleSidebarToggle: () => void;
  isMobileView: boolean;
}) => {
  const { data } = useSelector((state: RootState) => state.login);
  const items = data.item;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const handleProfile = () => {
    navigate("/");
    handleMenuClose();
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const calculateTopbarMarginLeft = () => {
    if (isMobileView) {
      return isSidebarOpen ? sizeConfigs.sidebar.width : 0;
    } else {
      return sizeConfigs.sidebar.width;
    }
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${calculateTopbarMarginLeft()})`,
        ml: calculateTopbarMarginLeft(),
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        transition: "margin 0.2s",
        background:"#f7f7f7",
      }}
    >
      <Toolbar>
        {isMobileView && (
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleSidebarToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
          <Avatar
            sx={{ marginRight: "1rem" }}
            alt={items.instituteName}
            src={items.logo}
          />
        {isMobileView ? (
          <Typography variant="h5">{items.instituteName}</Typography>
        ) : (
          <Typography variant="h5" color="#071976"><span style={{ color:"#03953c" }}>Welcome To ~ </span>{items.instituteName}</Typography>
        )}
        <div style={{ marginLeft: "auto" }}>
          <IconButton
            color="inherit"
            aria-label="user menu"
            onClick={handleMenuOpen}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                sx={{ margin: "auto" }}
                alt={items.studentName}
                src={`data:image/png;base64,${items.imageName}`}
              />
            </StyledBadge>
          </IconButton>
          <Menu
            sx={{ top: "50px" }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
