import { createSlice, PayloadAction } from "@reduxjs/toolkit";




// ------------------------Login State


type LoginState = {
  customStudentId: string;
  instituteId: string;
  data: any;
  loggedIn: boolean;
};

const initialLoginState: LoginState = {
  customStudentId: "",
  instituteId: "",
  data: "",
  loggedIn: false,
};

// Load the login state from local storage if it exists
const loginStateFromLocalStorage = localStorage.getItem("loginState");
const persistedLoginState = loginStateFromLocalStorage
  ? JSON.parse(loginStateFromLocalStorage)
  : null;

// Use the persisted login state if available, otherwise use the initial state
const initialState: LoginState = persistedLoginState || initialLoginState;

const SESSION_TIMEOUT = 10 * 60 * 1000;

const handleSessionTimeout = () => {
  localStorage.removeItem("loginState");
  window.location.reload(); 
};
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginData: (
      state,
      action: PayloadAction<{ customStudentId: string; instituteId: string; data: any }>
    ) => {
      state.customStudentId = action.payload.customStudentId;
      state.instituteId = action.payload.instituteId;
      state.data = action.payload.data;
      state.loggedIn = true;
      localStorage.setItem("loginState", JSON.stringify(state));
       // Set up session timeout
       setTimeout(handleSessionTimeout, SESSION_TIMEOUT);
    },
    logout: (state) => {
      state.customStudentId = "";
      state.instituteId = "";
      state.loggedIn = false;
      localStorage.removeItem("loginState");
    },
    // New reducer to handle user login and session timeout
    loginUser: (
      state,
      action: PayloadAction<{
        customStudentId: string;
        instituteId: string;
        data: any;
      }>
    ) => {
      state.customStudentId = action.payload.customStudentId;
      state.instituteId = action.payload.instituteId;
      state.data = action.payload.data;
      state.loggedIn = true;
      localStorage.setItem("loginState", JSON.stringify(state));

      // Set session timeout
      setTimeout(() => {
        state.customStudentId = "";
        state.instituteId = "";
        state.loggedIn = false;
        localStorage.removeItem("loginState");
      }, SESSION_TIMEOUT);
    },
  },
});

export const { setLoginData, logout } = loginSlice.actions;
export const loginReducer = loginSlice.reducer;


// ------------------appState


type AppState = {
  appState: string;
  data: any;
};

const initialAppState: AppState = {
  appState: "",
  data: null
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState: initialAppState,
  reducers: {
    setAppState: (state, action: PayloadAction<string>) => {
      state.appState = action.payload;
    },
  },
});

export const { setAppState  } = appStateSlice.actions;
export const appStateReducer = appStateSlice.reducer;
