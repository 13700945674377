import { BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import Login from "./components/Login/Login";
import { RootState } from "./redux/store";
import SessionTimeoutHandler from "./components/Login/SessionTimeoutHandler ";

function App() {
  const loggedIn = useSelector((state: RootState) => state.login.loggedIn);
  // console.log(loggedIn)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={loggedIn ? <MainLayout /> : <Navigate to="/login" />}
        >
          {routes}
        </Route>
      </Routes>
      <SessionTimeoutHandler />
    </BrowserRouter>
  );
}

export default App;

