import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material";

interface AdditionalColumn {
  id: string;
  label: string;
  showOnResponse: boolean;
  render?: (data: any, Index: number) => JSX.Element;
}

interface TableViewProps {
  columns: AdditionalColumn[];
  dataSource: any[];
  pagination?: any;
}

const TableView: React.FC<TableViewProps> = ({
  columns,
  dataSource,
  pagination,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: "1px solid #dedede",
    maxWidth: "200px",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#edfcf1",
      color: "#000",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column, Index) => (
                <StyledTableCell key={`header-${column.id}`}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  style={{ textAlign: "center", color: "#c1c7c3" }}
                >
                  No Data Available
                </TableCell>
              </TableRow>
            ) : (
              dataSource.map((rowData, Index) => (
                <TableRow key={`row-${Index}`}>
                  {columns.map((column, Index) =>
                    column.showOnResponse ? (
                      <StyledTableCell key={`cell-${Index}-${column.id}`}>
                        {column.render
                          ? column.render(rowData, Index)
                          : rowData[column.id]}
                      </StyledTableCell>
                    ) : null
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              {pagination !== false && ( 
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={12}
                  count={dataSource.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableView;
