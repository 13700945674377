// import { Outlet } from "react-router-dom";
// import { Box, ThemeProvider, Toolbar, createTheme } from "@mui/material";
// import colorConfigs from "../../configs/colorConfigs";
// import sizeConfigs from "../../configs/sizeConfigs";
// import Sidebar from "../common/Sidebar";
// import Topbar from "../common/Topbar";

// const defaultTheme = createTheme();

// const MainLayout = () => {
//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Box sx={{ display: "flex" }}>
//         <Topbar />
//         <Box
//           component="nav"
//           sx={{
//             width: sizeConfigs.sidebar.width,
//             flexShrink: 0,
//           }}
//         >
//           <Sidebar />
//         </Box>
//         <Box
//           component="main"
//           sx={{
//             flexGrow: 1,
//             p: 3,
//             width: `calc(100% - ${sizeConfigs.sidebar.width})`,
//             minHeight: "100vh",
//             backgroundColor: colorConfigs.mainBg,
//           }}
//         >
//           <Toolbar />
//           <Outlet />
//         </Box>
//       </Box>
//     </ThemeProvider>
//   );
// };

// export default MainLayout;

import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";

const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= sizeConfigs.mobileBreakpoint);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateSidebarWidth = () => {
    if (isMobileView) {
      return isSidebarOpen ? sizeConfigs.sidebar.width : 0;
    } else {
      return sizeConfigs.sidebar.width;
    }
  };

  const calculateMainLayoutWidth = () => {
    if (isMobileView) {
      return "100%";
    } else {
      return `calc(100% - ${sizeConfigs.sidebar.width})`;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar
        isSidebarOpen={isSidebarOpen}
        handleSidebarToggle={handleSidebarToggle}
        isMobileView={isMobileView}
      >
        <></>
      </Topbar>
      <Box
        component="nav"
        sx={{
          width: calculateSidebarWidth(),
          flexShrink: 0,
          transition: "width 0.2s",
        }}
      >
        <Sidebar isSidebarOpen={isMobileView ? isSidebarOpen : true} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: calculateMainLayoutWidth(),
          maxWidth: calculateMainLayoutWidth(),
          transition: "margin 0.2s",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
