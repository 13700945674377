import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import GradingIcon from '@mui/icons-material/Grading';
import AutofpsSelectIcon from '@mui/icons-material/AutofpsSelect';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SubjectIcon from '@mui/icons-material/Subject';
import PaidIcon from '@mui/icons-material/Paid';
import ResultPage from "../pages/result/ResultPage";
import FeesInfo from "../pages/account/FeesInfo";
import AttendanceInfoPage from "../pages/attendance/AttendanceInfoPage";
import RoutinePage from "../pages/routine/RoutinePage";
import SubjectInfoPage from "../pages/subject/SubjectInfoPage";
import DashboardPageLayout from "../pages/dashboard/DashboardPageLayout";
import { DashboardCustomizeOutlined } from "@mui/icons-material";

const appRoutes: RouteType[] = [
  {
    index: true,
    path: "/",
    element: <HomePage />,
    state: "home"
  },
  // {
  //   path: "/dashboard",
  //   element: <DashboardPageLayout />,
  //   state: "dashboard",
  //   sidebarProps: {
  //     displayText: "Dashboard",
  //     icon: <DashboardOutlinedIcon />
  //   },
  //   child: [
  //     {
  //       path: "/dashboard/information",
  //       element: <DefaultPage />,
  //       state: "dashboard.information",
  //       sidebarProps: {
  //         displayText: "Information"
  //       },
  //     }
  //   ]
  // },
  {
    path: "/",
    element: <DashboardPageLayout />,
    state: "home",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardCustomizeOutlined />
    },
    
  },
  
  {
    path: "/attendance",
    element: <AttendanceInfoPage />,
    state: "attendance",
    sidebarProps: {
      displayText: "Attendance Info",
      icon: <AutofpsSelectIcon />
    }
  },
  {
    path: "/result",
    element: <ResultPage />,
    state: "result",
    sidebarProps: {
      displayText: "Result Info",
      icon: <GradingIcon />
    }
  },
  {
    path: "/fees-info",
    element: <FeesInfo />,
    state: "fees-info",
    sidebarProps: {
      displayText: "Fees Info",
      icon: <PaidIcon />
    }
  },
  {
    path: "/routine-info",
    element: <RoutinePage />,
    state: "routine-info",
    sidebarProps: {
      displayText: "Routine Info",
      icon: <CalendarMonthIcon />
    }
  },
  {
    path: "/subject-info",
    element: <SubjectInfoPage />,
    state: "subject-info",
    sidebarProps: {
      displayText: "Subject Info",
      icon: <SubjectIcon />
    }
  },
];

export default appRoutes;