import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/appStateSlice";

const SESSION_TIMEOUT = 10 * 60 * 1000;

let sessionTimeoutId: NodeJS.Timeout | null = null;

const SessionTimeoutHandler = () => {
  const dispatch = useDispatch();

  // Function to handle user activity and reset session timeout
  const handleUserActivity = useCallback(() => {
    if (sessionTimeoutId) {
      clearTimeout(sessionTimeoutId);
    }
    sessionTimeoutId = setTimeout(() => {
      dispatch(logout());
      localStorage.removeItem("loginState");
      window.location.reload();
    }, SESSION_TIMEOUT);
  }, [dispatch]);

  // Add event listeners to handle user activity
  useEffect(() => {
    window.addEventListener("click", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);
    return () => {
      if (sessionTimeoutId) {
        clearTimeout(sessionTimeoutId);
      }
      window.removeEventListener("click", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
    };
  }, [handleUserActivity]);

  return null;
};

export default SessionTimeoutHandler;
