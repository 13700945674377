import React from 'react';

type Props = {};

const RoutinePage = (props:Props) => {
    return (
        <div>This Is Routine Page</div>
    );
};

export default RoutinePage;